.social-row {
    animation: delay-fade 4s ease-in;
    font-size: 20px;
    font-weight: lighter;
    align-self: center;
    letter-spacing: 0.2rem;
    color: gray;
}

@keyframes delay-fade {
    0% { opacity: 0; }
    33% { opacity: 0 }
    66% { opacity: 0 }
    100% { opacity: 1; }
}